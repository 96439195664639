@import '../node_modules/toastr/build/toastr.min.css';

cast-media-player {
	--theme-hue: 100;
	--progress-color: rgb(230, 0, 0);
	--background-image: url('images/default-bg.png');
	--logo-image: url('images/primaryLogo.png');
	--logo-size: 240px auto;
}

.toast-bottom-full-width {
	left: 50px;
	right: 50px;
	bottom: 50px;
	width: auto;
}

#toast-container>div {
	box-shadow: none;
}
